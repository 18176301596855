export const getDurationString = (duration) => {
    var milliseconds = ((duration % 1000) / 1000)
            .toFixed(3)
            .toString()
            .substr(1),
        seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return `${hours}:${minutes}:${seconds}${milliseconds}`;
};

export const getFileSizeString = (size) => {
    console.log("size", size);
    if (size === 0) return "0 B";
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "kB", "MB", "GB", "TB"][i]
    );
};

export const getDateTimeString = (date) => {
    return `${new Date(date).toLocaleDateString()} ${new Date(
        date
    ).toLocaleTimeString()}`;
};
