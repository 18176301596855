import { useState, useEffect } from "react";
import { api } from "../../support/httpClient";

export const useCompanyStats = () => {
    const [stats, setStats] = useState();
    useEffect(() => {
        if (!stats)
            (async () => {
                let response = await api.get("/company/stats");
                if (response?.data) setStats(response.data);
            })();
    });

    return stats;
};
