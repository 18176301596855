import React from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import {
    Button,
    makeStyles,
} from "@material-ui/core";

export default function Uploader(props) {
    const { buttonText, fileHandler, options } = props;
    const [open, setOpen] = React.useState(false);

    const dropzoneProps = {
        cancelButtonText: "Cancel",
        submitButtonText: "Submit",
        open: open,
        onClose: () => setOpen(false),
        onSave: (files) => {
            fileHandler(files);
            setOpen(false);
        },
        showPreviews: true,
        showFileNamesInPreview: true,

        ...options,
    };
    const useStyles = makeStyles((theme) => ({
        button: { 
            backgroundColor: "#2196F3",
            '&:hover': {
                backgroundColor: '#0C7CD5',
            }
        },

    }));

    const classes = useStyles();

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                className={classes.button}
            >
                {buttonText}
            </Button>

            <DropzoneDialog
                {...dropzoneProps}
                // acceptedFiles={[".csv"]}
                // cancelButtonText={["Cancel"]}
                // submitButtonText={["Submit"]}
                // filesLimit={1}
                // open={open}
                // onClose={() => setOpen(false)}
                // onSave={(file) => {
                //   fileHandler(file)
                //   setOpen(false);
                // }}
                // showPreviews={true}
                // showFileNamesInPreview={true}
            />
        </div>
    );
}
