import React from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100vh",
    },
    paper: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.secondary,
    },
    fullHeight: {
        height: "100%",
    },
    mainCard: {
        height: "60%",
    },
    infoCard: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.tertiary,
    },
    gridContainer: {
        spacing: 3,
        padding: 1,
    },
}));

const EmailOverview = () => {
    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={12} className={classes.mainCard}>
                    <Typography variant="h3">Email Automation</Typography>

                    <Card className={classes.paper}>
                        <CardHeader
                            title={
                                <Typography vairant="h4">
                                    Email Notifications for Encompass
                                </Typography>
                            }
                        />
                        <CardContent>
                            <Typography variant="body2">
                                Our email automation system enables you to
                                configure and send html emails for virtually
                                anyone based on changes to loan data. If you're
                                looking to take control of messaging to your
                                borrowers, agents, partners, loan officers,
                                processors, closers, funders, send us an email
                                at{" "}
                                <a href="mailto:support@maceinnovations.com">
                                    support@maceinnovations.com
                                </a>{" "}
                                to get started or schedule a demo.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default EmailOverview;
