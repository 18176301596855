import React, { useEffect, useState } from "react";
import { useAddFieldAccessRule, useFieldAccessRules } from "./updateAPI";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Input,
    InputLabel,
    makeStyles,
    Select,
    Typography,
} from "@material-ui/core";
import Table from "../../components/Table";
import { getDateTimeString } from "../../support/stringFormats";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    root: {
        flexGrow: 1,
        height: "100vh",
    },
    button: { 
        backgroundColor: "#2196F3",
        '&:hover': {
            backgroundColor: '#0C7CD5',
        }
    },
    paper: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.secondary,
    },
    fullHeight: {
        height: "100%",
    },
    mainCard: {
        height: "60%",
    },
    infoCard: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.tertiary,
    },
}));

const columns = [
    { name: "Field ID", selector: "id", sortable: true },
    { name: "Personas", selector: "persona", sortable: true, grow: 2 },
    {
        name: "Triggers Required",
        selector: "triggersRequired",
        sortable: true,
        format: (row) => (row.triggersRequired ? "True" : "False"),
    },
    {
        name: "Created",
        selector: "created",
        sortable: true,
        grow: 2,
        format: (row) => getDateTimeString(row.created),
    },
];

const StatusDisplay = (props) => {
    const [display, toggleDisplay] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            toggleDisplay(!display);
        }, 10000);
    });

    return display ? <Card>{props.status}</Card> : <></>;
};

const FieldAccess = () => {
    const { ruleStatus, addRule } = useAddFieldAccessRule();
    const classes = useStyles();
    const [fieldId, setFieldId] = useState("");
    const [persona, setPersona] = useState("");
    const [triggersRequired, setTriggersRequired] = useState(false);

    const personas = ["Every User", "Funder", "Compliance"];

    const { data, refresh } = useFieldAccessRules();


    return (
        <Grid container spacing={3} className={classes.gridContainer}>
            <Grid item xs={12} className={classes.mainCard}>
                <Card className={`${classes.paper} ${classes.fullHeight}`}>
                    <CardHeader
                        title={
                            <Typography variant="h3">
                                Add Field Access Rule
                            </Typography>
                        }
                    />
                    <CardContent>
                        <Typography>
                            Here you can GRANT access to any encompass field
                            based on the user's persona. You can also require
                            that triggers are enabled when the user uses the
                            bulk update tool. This will ensure users have access
                            to only the fields you allow them to modify. It will
                            also enforce your existing business rule if you
                            require triggers enabled.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <FormGroup row>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Field Id</InputLabel>
                                <Input
                                    placeholder="Field ID"
                                    value={fieldId}
                                    onChange={(e) => setFieldId(e.target.value)}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel>Persona</InputLabel>
                                <Select
                                    value={persona}
                                    native
                                    onChange={(e) => setPersona(e.target.value)}
                                >
                                    {personas.map((persona, i) => (
                                        <option value={persona} key={i}>
                                            {persona}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControlLabel
                                className={classes.formControl}
                                control={
                                    <Checkbox
                                        checked={triggersRequired}
                                        onChange={(e) =>
                                            setTriggersRequired(
                                                !triggersRequired
                                            )
                                        }
                                    />
                                }
                                label="Triggers Required"
                            />
                            <Button
                                className={classes.formControl}
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() =>
                                    addRule({
                                        fieldId: fieldId,
                                        persona: persona,
                                        triggersRequired: triggersRequired,
                                    })
                                }
                            >
                                Add
                            </Button>
                        </FormGroup>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <StatusDisplay status={ruleStatus} />
            </Grid>
            <Grid item xs={12}>
                <Table
                    title="Field Access Rules"
                    columns={columns}
                    searchable
                    data={data}
                    refresh={refresh}
                    />
            </Grid>
        </Grid>
    );
};
export default FieldAccess;
