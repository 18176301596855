import React, { useCallback, useEffect } from "react";
import {
    Button,
    FormControl,
    Input,
    InputLabel,
    makeStyles,
} from "@material-ui/core";
import DataTable from "react-data-table-component";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100vh",
    },
    paper: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.secondary,
    },
    fullHeight: {
        height: "100%",
    },
    mainCard: {
        height: "60%",
    },
    infoCard: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.tertiary,
    },
}));

const SearchHeader = ({ filterText, onFilter, onClear, onRefresh }) => (
    <>
        <FormControl>
            <InputLabel>Search</InputLabel>
            <Input
                id="search"
                type="text"
                placeholder="Search"
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
            />
        </FormControl>
        <Button variant="contained" type="button" onClick={onClear}>
            X
        </Button>
        <Button variant="contained" type="button" onClick={onRefresh}>
            Refresh
        </Button>
    </>
);

const Table = ({
    columns,
    data,
    refresh,
    title,
    searchable,
    detailView,
    exportable,
}) => {
    const DetailView = detailView;
    const classes = useStyles();
    const [tableData, setTableData] = React.useState([]);
    const [filteredTableData, setFilteredTableData] = React.useState(tableData);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    const [filter, setFilter] = React.useState("");

    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ",";
        const lineDelimiter = "\n";
        const keys = Object.keys(filteredTableData[0]);

        result = "";
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach((item) => {
            let ctr = 0;
            keys.forEach((key) => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    function downloadCSV(array) {
        console.log(array);
        const link = document.createElement("a");
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = "export.csv";

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute("href", encodeURI(csv));
        link.setAttribute("download", filename);
        link.click();
    }

    useEffect(() => {
        setResetPaginationToggle(!resetPaginationToggle);
        search();
    }, [tableData, filter]);

    const search = () => {
        const results =
            tableData && tableData.length > 0 && filter !== ""
                ? tableData.filter((item) => {
                      let found = false;
                      columns.forEach((column) => {
                          if (!found && item[column.selector]) {
                              let text = ["number", "boolean"].includes(
                                  typeof item[column.selector]
                              )
                                  ? item[column.selector].toString()
                                  : item[column.selector];
                              found = text.includes(filter);
                          }
                      });
                      return found;
                  })
                : tableData;
        setFilteredTableData(results);
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        const onFilter = (e) => {
            setFilter(e.target.value);
        };

        const onClear = () => {
            setFilter("");
        };

        const onRefresh = () => {
            refresh();
        };
        return (
            <SearchHeader
                filterText={filter}
                onFilter={onFilter}
                onClear={onClear}
                onRefresh={onRefresh}
            />
        );
    }, [filter, resetPaginationToggle]);

    useEffect(() => {
        if(data){
            setFilter("");
            setTableData(data);
        }
    }, [data]);

    const searchableProps = {
        subHeader: true,
        subHeaderComponent: subHeaderComponentMemo,
    };
    const detailProps = DetailView
        ? {
              expandableRows: true,
              expandableRowsComponent: <DetailView />,
              expandableInheritConditionalStyles: true,
              expandOnRowClicked: true,
          }
        : null;
    const Export = ({ onExport }) => (
        <Button variant="contained" onClick={(e) => onExport(e.target.value)}>
            Export
        </Button>
    );
    const actionsMemo = React.useMemo(
        () => <Export onExport={() => downloadCSV(filteredTableData)} />,
        []
    );

    return (
        <DataTable
            title={title}
            columns={columns}
            data={filteredTableData}
            pagination
            paginationRowsPerPageOptions={[10, 15, 25, 50]}
            paginationResetDefaultPage={resetPaginationToggle}
            {...(searchable ? searchableProps : {})}
            {...(detailView ? detailProps : {})}
            {...(exportable ? { actions: actionsMemo } : {})}
        />
    );
};
export default Table;
