import React from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100vh",
    },
    paper: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.secondary,
    },
    fullHeight: {
        height: "100%",
    },
    mainCard: {
        height: "60%",
    },
    infoCard: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.tertiary,
    },
    gridContainer: {
        spacing: 3,
        padding: 1,
    },
}));

const ImporterOverview = () => {
    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={12} className={classes.mainCard}>
                    <Typography variant="h3">Document Importer</Typography>

                    <Card className={classes.paper}>
                        <CardHeader
                            title={
                                <Typography vairant="h4">
                                    Ready. Set. Upload!
                                </Typography>
                            }
                        />
                        <CardContent>
                            <Typography variant="body2">
                                Our document importer tool allows you to utilize
                                high speed scanners and quickly get documents
                                into the eFolder within Encompass.
                            </Typography>
                            <Typography variant="body2">
                                You have 20 documents to try it out, then shoot
                                us an email at{" "}
                                <a href="mailto:support@maceinnovations.com">
                                    support@maceinnovations.com
                                </a>{" "}
                                if you want to move forward with purchasing.
                            </Typography>
                        </CardContent>
                    </Card>
                    <Grid
                        container
                        item
                        direction="row"
                        justify="space-around"
                        alignItems="stretch"
                        spacing={3}
                        xs={12}
                    >
                        <Grid item sm={4}>
                            <Card
                                className={`${classes.paper} ${classes.fullHeight}`}
                            >
                                <CardHeader
                                    title={
                                        <Typography variant="h5">
                                            The Basics
                                        </Typography>
                                    }
                                />
                                <CardContent>
                                    <ul>
                                        <li>
                                            Uses the EncompassNG API, no SDK
                                            needed
                                        </li>
                                        <li>
                                            Imports documents in less than 20
                                            seconds
                                        </li>
                                        <li>
                                            User notifications when documents
                                            are uploaded
                                        </li>
                                        <li>
                                            Works with ANY scanner (make and
                                            model)
                                        </li>
                                        <li>
                                            UNLIMITED number of scan stations
                                            supported
                                        </li>
                                        <li>
                                            Live View
                                            <ul>
                                                <li>
                                                    Realtime view of document
                                                    imports
                                                </li>
                                                <li>
                                                    Daily, weekly and monthly
                                                    reports
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item sm={4}>
                            <Card
                                className={`${classes.paper} ${classes.fullHeight}`}
                            >
                                <CardHeader
                                    title={
                                        <Typography variant="h5">
                                            Features
                                        </Typography>
                                    }
                                />
                                <CardContent>
                                    <ul>
                                        <li>
                                            Add documents into the eFolder
                                            <ul>
                                                <li>
                                                    File Manager / Unassigned
                                                </li>
                                                <li>
                                                    eFolder bucket / document
                                                    placeholder
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Short Code Support
                                            <ul>
                                                <li>
                                                    Abbreviated folder names
                                                </li>
                                                <li>
                                                    Reduces Rescans and errors
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Email Notifications
                                            <ul>
                                                <li>
                                                    Global email distribution or
                                                </li>
                                                <li>
                                                    Email distributions based on
                                                    Short Code
                                                </li>
                                                <li>
                                                    Success and Error Email
                                                    notifications
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Duplicate document for Auto Assign
                                            functionality
                                        </li>
                                    </ul>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item sm={4}>
                            <Card
                                className={`${classes.paper} ${classes.fullHeight}`}
                            >
                                <CardHeader
                                    title={
                                        <Typography variant="h5">
                                            Getting Started
                                        </Typography>
                                    }
                                />
                                <CardContent>
                                    <ul>
                                        <li>
                                            Requirements
                                            <ul>
                                                <li>
                                                    {
                                                        "Access to the EncompassNG Api. (keys added under Settings -> Encompass)"
                                                    }
                                                </li>
                                                <li>
                                                    Windows 7 and newer
                                                    operating system
                                                </li>
                                                <li>
                                                    Ability to save a scanned
                                                    document as a PDF
                                                </li>
                                                <li>
                                                    PDF name must include the
                                                    loan number
                                                </li>
                                                <li>
                                                    See the getting started
                                                    guide for Short Code support
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Download and Install</li>
                                        <li>
                                            When Prompted, Login with your Mace
                                            Portal Credentials
                                        </li>
                                        <li>Scan your first document!</li>
                                    </ul>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Typography variant="body2">
                        Currently some of the settings below need to be
                        configured by support. Short Codes, Global Email
                        Distribution and Duplicates for Auto Assign will need to
                        be setup by support. We are adding the ability to manage
                        all features via the portal in the coming weeks. Shoot
                        us an email and we will get you setup quickly. Stay
                        tuned for more updates!
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ImporterOverview;
