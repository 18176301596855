import { useEffect, useState } from "react";
import { api } from "../../support/httpClient";

export const getEmailHistory = () => {
    return api.get("/email/history");
};

export const useEmailHistoryEmails = () => {
    const [data, setData] = useState();
    const [shouldRefresh, refresh] = useState();

    useEffect(async () => {
        const response = await api.get("/email/history");
        if (response?.data?.emails) setData(response.data.emails);
    }, [shouldRefresh]);

    return { data, refresh };
};

export const useEmailHistory = () => {
    const [data, setData] = useState();
    useEffect(async () => {
        if (!data) {
            const response = await api.get("/email/history");
            if (response?.data) setData(response.data);
        }
    });
    return data;
};
