import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";

import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  ExpandLess,
  ExpandMore,
  Publish,
  ChevronRight,
  NoteAdd,
  Email,
  Sync,
  Send,
  Description,
  Settings,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  nested: {
      paddingLeft: theme.spacing(4),
  },
}));

export const Paths = {
  DASHBOARD: "/dashboard",
  BULKUPDATER_UPDATE: "/bulkupdater/update",
  BULKUPDATER_HISTORY: "/bulkupdater/history",
  BULKUPDATER_FIELDACCESS: "/bulkupdater/fieldaccess",
  DOCIMPORTER_OVERVIEW: "/documentimporter/overview",
  DOCIMPORTER_HISTORY: "/documentimporter/history",
  DOCIMPOTER_SETTINGS: "/documentimporter/settings",
  EMAIL_OVERVIEW: "/email/overview",
  EMAIL_HISTORY: "/email/history",
  AUTOMATION_OVERVIEW: "/taskautomation/overview",
  AUTOMATION_HISTORY: "/taskautomation/history",
  BLEND_OVERVIEW: "/blend/overview",
  BLEND_AUTO_PUSH_HISTORY: "/blend/app-sync-history",
  BLEND_INTEGRATION_LOG: "/blend/log",
  BLEND_SETTINGS: "/blend/settings",
  OCR_MANAGE: "/ocr/manage",
  OCR_MAPPING_TOOL: "/ocr/mapit",
  SETTINGS_USERS: "/configuration/manageusers",
  SETTINGS_ENCOMPASS: "/configuration/configure",
};
Object.freeze(Paths);

const bulkupdaterPaths = [
  Paths.BULKUPDATER_FIELDACCESS,
  Paths.BULKUPDATER_HISTORY,
  Paths.BULKUPDATER_UPDATE,
];
const docImporterPaths = [
  Paths.DOCIMPORTER_HISTORY,
  Paths.DOCIMPORTER_OVERVIEW,
  Paths.DOCIMPOTER_SETTINGS,
];
const emailPaths = [Paths.EMAIL_OVERVIEW, Paths.EMAIL_HISTORY];
const taskAutomationPaths = [
  Paths.AUTOMATION_HISTORY,
  Paths.AUTOMATION_OVERVIEW,
];
const blendPaths = [
  Paths.BLEND_AUTO_PUSH_HISTORY,
  Paths.BLEND_INTEGRATION_LOG,
  Paths.BLEND_OVERVIEW,
  Paths.BLEND_SETTINGS,
];
const ocrPaths = [
  Paths.OCR_MANAGE,
  Paths.OCR_MAPPING_TOOL,
];
const SettingsPaths = [Paths.SETTINGS_ENCOMPASS, Paths.SETTINGS_USERS];

export default function NavMenu() {
  const history = useHistory();

  const [currentPath, setCurrentPath] = React.useState(
      history.location.pathname
  );

  const [buOpen, setBuOpen] = React.useState(
      bulkupdaterPaths.includes(currentPath)
  );
  const toggleBU = () => setBuOpen(!buOpen);

  const [diOpen, setDiOpen] = React.useState(
      docImporterPaths.includes(currentPath)
  );
  const toggleDI = () => setDiOpen(!diOpen);

  const [emailOpen, setEmailOpen] = React.useState(
      emailPaths.includes(currentPath)
  );
  const toggleEmail = () => setEmailOpen(!emailOpen);

  const [taOpen, setTaOpen] = React.useState(
      taskAutomationPaths.includes(currentPath)
  );
  const toggleTA = () => setTaOpen(!taOpen);

  const [blendOpen, setBlendOpen] = React.useState(
      blendPaths.includes(currentPath)
  );
  const toggleBlendOpen = () => setBlendOpen(!blendOpen);

  const [dmOpen, setDmOpen] = React.useState(
      ocrPaths.includes(currentPath)
  );
  const toggleDmOpen = () => setDmOpen(!dmOpen);

  const [settingsOpen, setSettingsOpen] = React.useState(
      SettingsPaths.includes(currentPath)
  );
  const toggleSettingsOpen = () => setSettingsOpen(!settingsOpen);

  useEffect(() => {
      return history.listen((location) => {
          setCurrentPath(location.pathname);
      });
  }, [history]);

  useEffect(() => {
      if (bulkupdaterPaths.includes(currentPath)) setBuOpen(true);
      else if (docImporterPaths.includes(currentPath)) setDiOpen(true);
      else if (emailPaths.includes(currentPath)) setEmailOpen(true);
      else if (taskAutomationPaths.includes(currentPath)) setTaOpen(true);
      else if (blendPaths.includes(currentPath)) setBlendOpen(true);
      else if (ocrPaths.includes(currentPath)) setDmOpen(true);
      else if (SettingsPaths.includes(currentPath)) setSettingsOpen(true);
  }, [currentPath]);

  const SubItem = (props) => {
      const classes = useStyles();

      return (
          <ListItem
              selected={currentPath === props.to}
              button
              component={Link}
              to={props.to}
              className={classes.nested}
          >
              <ListItemIcon>
                  <ChevronRight />
              </ListItemIcon>
              <ListItemText primary={props.text} />
          </ListItem>
      );
  };

  return (
      <List component="nav">
          <ListItem
              selected={currentPath === Paths.DASHBOARD}
              button
              component={Link}
              to={Paths.DASHBOARD}
          >
              <ListItemIcon>
                  <HomeIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={toggleBU}>
              <ListItemIcon>
                  <Publish color="primary" />
              </ListItemIcon>
              <ListItemText primary="Bulk Updater" />
              {buOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={buOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  <SubItem
                      index={1}
                      text="Update Loans"
                      to={Paths.BULKUPDATER_UPDATE}
                  />
                  <SubItem
                      index={2}
                      text="History"
                      to={Paths.BULKUPDATER_HISTORY}
                  />
                  <SubItem
                      index={3}
                      text="Field Acccess Rules"
                      to={Paths.BULKUPDATER_FIELDACCESS}
                  />
              </List>
          </Collapse>
         <ListItem button onClick={toggleDI}>
              <ListItemIcon>
                  <NoteAdd color="primary" />
              </ListItemIcon>
              <ListItemText primary="Doc Importer" />
              {diOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={diOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  <SubItem
                      index={4}
                      text="Overview"
                      to={Paths.DOCIMPORTER_OVERVIEW}
                  />
                  <SubItem
                      index={5}
                      text="History"
                      to={Paths.DOCIMPORTER_HISTORY}
                  />
                  <SubItem
                      index={6}
                      text="Settings"
                      to={Paths.DOCIMPOTER_SETTINGS}
                  />
              </List>
  </Collapse>
          <ListItem button onClick={toggleEmail}>
              <ListItemIcon>
                  <Email color="primary" />
              </ListItemIcon>
              <ListItemText primary="Email" />
              {emailOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={emailOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  <SubItem
                      index={7}
                      text="Overview"
                      to={Paths.EMAIL_OVERVIEW}
                  />
                  <SubItem
                      index={8}
                      text="History"
                      to={Paths.EMAIL_HISTORY}
                  />
              </List>
          </Collapse>
          <ListItem button onClick={toggleTA}>
              <ListItemIcon>
                  <Sync color="primary" />
              </ListItemIcon>
              <ListItemText primary="Task Automation" />
              {taOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={taOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  <SubItem
                      index={9}
                      text="Overview"
                      to={Paths.AUTOMATION_OVERVIEW}
                  />
                  <SubItem
                      index={10}
                      text="History"
                      to={Paths.AUTOMATION_HISTORY}
                  />
              </List>
          </Collapse>
          <ListItem button onClick={toggleBlendOpen}>
              <ListItemIcon>
                  <Send color="primary" />
              </ListItemIcon>
              <ListItemText primary="Blend" />
              {blendOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={blendOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  <SubItem
                      index={11}
                      text="Overview"
                      to={Paths.BLEND_OVERVIEW}
                  />
                  <SubItem
                      index={12}
                      text="Auto Push History"
                      to={Paths.BLEND_AUTO_PUSH_HISTORY}
                  />
                  <SubItem
                      index={13}
                      text="Integration Log"
                      to={Paths.BLEND_INTEGRATION_LOG}
                  />
                  <SubItem
                      index={14}
                      text="Settings"
                      to={Paths.BLEND_SETTINGS}
                  />
              </List>
          </Collapse>
          <ListItem button onClick={toggleDmOpen}>
              <ListItemIcon>
                  <Description color="primary" />
              </ListItemIcon>
              <ListItemText primary="OCR" />
              {dmOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={dmOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  <SubItem
                      index={15}
                      text="Manage"
                      to={Paths.DOCMAPPING_MANAGE}
                  />
                  <SubItem
                      index={16}
                      text="Mapping Tool"
                      to={Paths.DOCMAPPING_MAPPING_TOOL}
                  />
              </List>
          </Collapse>
          <ListItem button onClick={toggleSettingsOpen}>
              <ListItemIcon>
                  <Settings color="primary" />
              </ListItemIcon>
              <ListItemText primary="Settings" />
              {settingsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                  <SubItem
                      index={17}
                      text="Users"
                      to={Paths.SETTINGS_USERS}
                  />
                  <SubItem
                      index={18}
                      text="Encompass"
                      to={Paths.SETTINGS_ENCOMPASS}
                  />
              </List>
          </Collapse>
      </List>
  );
}
