import { useEffect, useState } from "react";
import { api } from "../../support/httpClient";

export const useDocumentHistory = () => {
    const [data, setData] = useState();
    useEffect(async () => {
        if (!data) {
            const response = await api.get("/document/history");
            if (response?.data) setData(response.data);
        }
    });
    return data;
};

export const useDocumentHistoryTasks = () => {
    const [data, setData] = useState();
    const [shouldRefresh, refresh] = useState();

    useEffect(async () => {
        const response = await api.get("/document/history");
        if (response?.data?.tasks) setData(response.data.tasks);
    }, [shouldRefresh]);

    return { data, refresh };
};

export const useDocumentConfigGet = () => {
    const [data, setData] = useState();
    const [shouldRefresh, refresh] = useState();

    useEffect(async () => {
        const response = await api.get("/document/config/get");
        if (response?.data?.documentMappings)
            setData(response.data.documentMappings);
    }, [shouldRefresh]);
    return { data, refresh };
};

export const useDocumentConfigCreate = () => {
    const [shouldAdd, addMapping] = useState();
    const [data, setData] = useState();

    useEffect(async () => {
        if (shouldAdd) {
            const response = await api.post(
                "/document/config/create",
                shouldAdd
            );
            if (response?.data) setData(response.data);
            addMapping(null);
        }
    });
    return { data, addMapping };
};

export const useDocumentConfigUpdate = () => {
    const [shouldUpdate, updateConfig] = useState();
    const [data, setData] = useState();

    useEffect(() => {
        if (shouldUpdate)
            (async () => {
                const response = await api.post(
                    "/document/config/update",
                    shouldUpdate
                );
                if (response?.data) setData(response.data);
                updateConfig(null);
            })();
    });

    return { data, updateConfig };
};
