import {
    Container,
    Grid,
    Card,
    Typography,
    CardContent,
    CardActions,
    Button,
    makeStyles,
    List,
    ListItem,
    ListItemText,
    Collapse,
    Box,
} from "@material-ui/core";
import {
    ExpandLess,
    ExpandMore,
    Business,
    Update,
    Timer,
    CloudUpload,
} from "@material-ui/icons";
import React, { useEffect, useState, storage, queryParams } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { DashboardWidget } from "../../components/DashboardWidget";
import { useCompanyStats } from "./dashboardAPI";
import * as qs from 'query-string'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100vh",
    },
    row: {
        card: {},
    },
    button: { 
        backgroundColor: "#2196F3",
        '&:hover': {
            backgroundColor: '#0C7CD5',
        }
    },
    paper: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.secondary,
    },
    center: {
        textAlign: "center",
        justifyContent: "center",
    },
    fullHeight: {
        height: "100%",
    },
    subtitle: {
        marginBottom: 12,
    },
    mainCard: {
        height: "60%",
    },
    statItems: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
    statList: {
        width: "100%",
        justify: "space-around",
        color: theme.palette.text.secondary,
        hr: {
            alginSelf: "stretch",
            height: "auto",
        },
        flexWrap: "nowrap",
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    reportHeader: {
        width: "100%",
        display: "flex",
        justify: "space-around",
        textAlign: "left",
        div: {},
    },
    reportList: {
        display: "flex",
        flexDirection: "column",
    },
}));

export default function Dashboard() {
    const history = useHistory();

    const location = useLocation();
        React.useEffect(()=>{
            //console.log(qs);
            let queryParams = qs.parse(location.search, { ignoreQueryPrefix: false });
            //console.log(queryParams);
            if(queryParams.authBridge)
            {
                //console.log(storage);
                localStorage.setItem("MI_ID_TOKEN", queryParams.authBridge);
            }
            

        },[]);

    const routeChange = (path) => {
        history.push(path);
    };

    const classes = useStyles();
    const stats = useCompanyStats();

    const [openReport, setOpenReport] = useState(-1);

    const Reports = { ENCOMPASS: 0, ELLIEMAE: 1, ENCOMPASSDEV: 2 };
    Object.freeze(Reports);

    const expandReport = (e, prop) =>
        setOpenReport(openReport === prop ? -1 : prop);

    const ReportHeader = (props) => {
        const { reportEnum, title, status } = props;
        return (
            <ListItem
                button
                onClick={(e) => expandReport(e, reportEnum)}
                selected={openReport === reportEnum}
            >
                <Box style={{ paddingRight: 5, width: "40%" }}>{title}</Box>
                <ListItemText disableTypography>{status}</ListItemText>

                {openReport === reportEnum ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
        );
    };

    return (
        <Container name="dashboard">
            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={12} sm={6}>
                    <Card className={`${classes.paper} ${classes.center}`}>
                        <CardContent>
                            <Typography variant="h3">
                                Bulk Loan Updater
                            </Typography>
                            <Typography
                                classname={classes.subtitle}
                                color="textSecondary"
                            >
                                Bulk Update Loans within Encompass
                            </Typography>
                            <Typography variant="body2" component="p">
                                It's Simple, Secure and Reliable.
                            </Typography>
                        </CardContent>
                        <CardActions className={classes.center}>
                            <Button
                                onClick={() => {
                                    routeChange("/bulkupdater/update");
                                }}
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}
                            >
                                Let's Go!
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card className={`${classes.paper} ${classes.center}`}>
                        <CardContent>
                            <Typography variant="h3">
                                Document Importer
                            </Typography>
                            <Typography
                                classname={classes.subtitle}
                                color="textSecondary"
                            >
                                Quickly upload documents to the eFolder
                            </Typography>
                            <Typography variant="body2" component="p">
                                It just works. Try it now!
                            </Typography>
                        </CardContent>
                        <CardActions className={classes.center}>
                            <Button
                                onClick={() => {
                                    routeChange("/docimporter/overview");
                                }}
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}
                            >
                                Let's Go!
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <DashboardWidget
                    items={[
                        {
                            title: "Company Bulk Update",
                            detail: stats?.companyBulkUpdates || 0,
                            icon: <Business />,
                        },
                        {
                            title: "Company Loans Updated",
                            detail: stats?.loansUpdated || 0,
                            icon: <Update />,
                        },
                        {
                            title: `Performance\nNo Triggers/Triggers`,
                            detail: `${
                                stats?.triggersDisabledAverageSeconds || 0
                            }s/${stats?.triggersEnabledAverageSeconds || 0}s`,
                            icon: <Timer />,
                        },
                        {
                            title: "My Bulk Updates",
                            detail: stats?.userBulkUpdates || 0,
                            icon: <CloudUpload />,
                        },
                    ]}
                />
                <Grid item xs={12} sm={12}>
                    <Card className={classes.paper}>
                        <List className={classes.reportList}>
                            <ReportHeader
                                reportEnum={Reports.ENCOMPASS}
                                title={"Encompass"}
                                status={"Operating Normally"}
                            />
                            <Collapse
                                in={openReport === Reports.ENCOMPASS}
                                unmountOnExit
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    className={classes.nested}
                                >
                                    Report
                                </Grid>
                            </Collapse>
                            <ReportHeader
                                reportEnum={Reports.ELLIEMAE}
                                title={"Ellie Mae Network Services"}
                                status={"Operating Normally"}
                            />

                            <Collapse
                                in={openReport === Reports.ELLIEMAE}
                                unmountOnExit
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    className={classes.nested}
                                >
                                    Report
                                </Grid>
                            </Collapse>
                            <ReportHeader
                                reportEnum={Reports.ENCOMPASSDEV}
                                title={"Encompass Developer Connect"}
                                status={"Operating Normally"}
                            />
                            <Collapse
                                in={openReport === Reports.ENCOMPASSDEV}
                                unmountOnExit
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    className={classes.nested}
                                >
                                    Report
                                </Grid>
                            </Collapse>
                        </List>
                        {/* <DataTable columns={columns} data={serviceStatus} expandableRows expandableRowsComponent={<ServiceReport />} /> */}
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
