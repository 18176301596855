import React, { useState, useEffect, useRef } from 'react';

function useInterval(callback, delay, options) {
  const savedCallback = useRef();
  const [attempts, setAttempts] = useState();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if(options.maxAttempts && attempts > options.maxAttempts){
        options.onMaxAttempts?.();
      } else {
        savedCallback.current();
        if(options.maxAttempts){
          setAttempts(attempts ? attempts + 1 : 1);
        }
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default useInterval;
