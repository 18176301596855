import {
    Card,
    CardContent,
    Divider,
    Grid,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100vh",
    },
    paper: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.secondary,
    },
    fullHeight: {
        height: "100%",
    },
    mainCard: {
        height: "60%",
    },
    infoCard: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.tertiary,
    },
    statItems: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
    },
    statList: {
        width: "100%",
        justify: "space-around",
        color: theme.palette.text.secondary,
        hr: {
            alginSelf: "stretch",
            height: "auto",
        },
        flexWrap: "nowrap",
    },
}));

export const DashboardWidget = (props) => {
    const { items } = props;
    const classes = useStyles();
    const StatusDetail = (props) => {
        const { title, detail, icon } = props;
        return (
            <Grid item className={classes.statItems} md={3} sm={6} xs={12}>
                {icon}
                <p>{title}</p>
                <h3>{detail}</h3>
            </Grid>
        );
    };
    return (
        <Grid item sm={12}>
            <Card className={`${classes.paper} ${classes.center}`}>
                <CardContent>
                    <Grid
                        container
                        className={`${classes.center} ${classes.statList}`}
                    >
                        {items.map((item, i) => (
                            <>
                                <StatusDetail
                                    key={i}
                                    title={item.title}
                                    detail={item.detail}
                                    icon={item.icon}
                                />
                                {items.length > 1 && items.length !== i + 1 ? (
                                    <Divider orientation="vertical" flexItem />
                                ) : null}
                            </>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};
