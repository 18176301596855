// in src/App.js
import * as React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { AppBar, Drawer, IconButton, makeStyles, Toolbar, Typography } from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import merge from "lodash/merge";
import MenuIcon from "@material-ui/icons/Menu";
import SettingsIcon from "@material-ui/icons/Settings";
import clsx from "clsx";
import NavMenu, { Paths } from "./components/NavMenu";
import UpdateLoans from "./pages/bulkupdater/UpdateLoans";
import UpdateHistory from "./pages/bulkupdater/UpdateHistory";
import Dashboard from "./pages/dashboard/Dashboard";
import FieldAccess from "./pages/bulkupdater/FieldAccess";
import ImporterOverview from "./pages/docimporter/ImporterOverview";
import ImporterHistory from "./pages/docimporter/ImporterHistory";
import EmailOverview from "./pages/email/EmailOverview";
import EmailHistory from "./pages/email/EmailHistory";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
  },
    
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#2196F3"
  },
  menuButtton: {
    marginRight: theme.spacing(2),
    
  },

  title: { flexGrow: 1 },
  toolbar: {},
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth
    
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "lightgray",
    marginLeft: -drawerWidth,
    
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  spacer: {
    display: "flex",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
}));

function App() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (

    <div className={classes.root} >
      <Router>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              MI
            </Typography>
            <IconButton edge="end" color="inherit" aria-label="settings">
              <SettingsIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.spacer}>&nbsp;</div>
          <div className={classes.drawerContainer}>
            <NavMenu />
          </div>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.spacer}>&nbsp;</div>
          <Switch >
            <Route exact path="/">
              <Redirect to={Paths.DASHBOARD} />
            </Route>
            <Route exact path={Paths.DASHBOARD}>
              <Dashboard />
            </Route>
            <Route exact path={Paths.BULKUPDATER_UPDATE}>
              <UpdateLoans />
            </Route>
            <Route exact path={Paths.BULKUPDATER_HISTORY}>
              <UpdateHistory />
            </Route>
            <Route exact path={Paths.BULKUPDAETR_FIELDACCESS}>
              <FieldAccess />
            </Route>
           <Route exact path={Paths.DOCIMPORTER_OVERVIEW}>
              <ImporterOverview />
            </Route>
            <Route exact path={Paths.DOCIMPORTER_HISTORY}>
              <ImporterHistory />
            </Route>
            <Route exact path={Paths.EMAIL_OVERVIEW}>
              <EmailOverview />
            </Route>
            <Route exact path={Paths.EMAIL_HISTORY}>
              <EmailHistory />
        </Route>
          </Switch>
        </main>
      </Router>
    </div>
  );
}

export default App;
