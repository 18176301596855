import React, { useState } from "react";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    LinearProgress,
    makeStyles,
    Typography,
} from "@material-ui/core";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import Uploader from "../../components/Uploader";
import Alert from "../../components/Alert";
import { useBulkUpdateUploader } from "./updateAPI";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100vh",
    },
    paper: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.secondary,
    },
    fullHeight: {
        height: "100%",
    },
    mainCard: {
        height: "60%",
    },
    infoCard: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.tertiary,
    },
}));

const StatusEnum = {
    UNSUBMITTED: 0,
    QUEUED: 1,
    PROCESSING: 2,
    FAILED: 3,
    SUCCESS: 4,
};
Object.freeze(StatusEnum);

export default function UpdateLoans() {
    const classes = useStyles();
    const [triggersEnabled, setTriggersEnabled] = useState(false);
    const [auditEnabled, setauditEnabled] = useState(false);
    const [fileName, setFileName] = useState("");
    const { uploadStatus, upload } = useBulkUpdateUploader();

    const processUpload = async (files) => {
        const file = files[0];
        setFileName(file.name);
        const formData = new FormData();

        formData.append("file", file);

        upload({formData, triggers: {
            triggersEnabled: triggersEnabled,
            auditEnabled: auditEnabled,
        }});
    };

    const statusId = uploadStatus?.status?.id;

    return (
        <div name="updateLoansContainer">
            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={12} className={classes.mainCard}>
                    <Card className={`${classes.paper} ${classes.fullHeight}`}>
                        <CardHeader
                            title={
                                <Typography variant="h3">
                                    Bulk Update Loans
                                </Typography>
                            }
                            action={
                                <Container>
                                    <FiberNewIcon />
                                    <a
                                        href="assets/bulk-updater-walkthrough.pdf"
                                        target="_blank"
                                    >
                                        Bulk Update Guide / Walkthrough
                                    </a>
                                </Container>
                            }
                        />
                        <Card className={classes.infoCard}>
                            <CardHeader
                                title={
                                    <Typography variant="h5">
                                        Did you know?
                                    </Typography>
                                }
                            />
                            <CardContent>
                                {}
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    Bulk updates continue to run in the
                                    background even if you navigate away or log
                                    out. You can start a large bulk update and
                                    come back later to check the status. To find
                                    the status of your bulk update click the [
                                    <a href="/bulkupdater/history">History</a>]
                                    link under [Bulk Updater] to view all your
                                    bulk updates and status. Processing Bulk
                                    updates show in Yellow.
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className={classes.infoCard}>
                            <CardHeader
                                title={
                                    <Typography variant="h5">
                                        Triggers, Business Rules and Backup
                                        Files
                                    </Typography>
                                }
                            />
                            <CardContent>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    If you need business rules to fire please
                                    check the Open/Close (Triggers Enabled) box.
                                    When this option is enabled bulk updating
                                    will process slower, however all of your
                                    business rules will fire. If you would like
                                    a backup file with bulk update results
                                    please check the Backup File Enabled option.
                                    You can download a copy once updates finish.
                                </Typography>
                            </CardContent>
                        </Card>
                        {[StatusEnum.QUEUED, StatusEnum.PROCESSING].includes(
                            statusId
                        ) ? (
                            <Container>
                                <Typography variant="body1" color="inherit">
                                    Processing "{fileName}"...
                                </Typography>
                                <LinearProgress />
                            </Container>
                        ) : (
                            <Container>
                                <Typography variant="h6" color="inherit">
                                    Submit Bulk Update:
                                </Typography>
                                {statusId ===
                                StatusEnum.SUCCESSFUL ? (
                                    <Container>
                                        <Alert
                                            title="Succeeded"
                                            severity="success"
                                            content={
                                                <div>
                                                    <ul>
                                                        <li>
                                                            {
                                                                uploadStatus.files?.find?.(
                                                                    (file) =>
                                                                        file.fileType ===
                                                                        "FileToProcess"
                                                                )?.fileName
                                                            }{" "}
                                                            uploaded
                                                            successfully.
                                                        </li>
                                                        <li>
                                                            {"x"} of {"y"} loans
                                                            processed.
                                                        </li>
                                                    </ul>
                                                </div>
                                            }
                                        />
                                        {uploadStatus?.files?.[2]?.url ? (
                                            <Alert
                                                title="Info"
                                                severity="info"
                                                content={
                                                    <Container>
                                                        <p>
                                                            * The status file
                                                            (csv) contains the
                                                            status of the bulk
                                                            update per loan. We
                                                            recommend checking
                                                            the status file to
                                                            ensure the last
                                                            column reads
                                                            "SUCCESS". This lets
                                                            you know the bulk
                                                            update was
                                                            successful for that
                                                            loan. If you see
                                                            "FAILED" in the last
                                                            column you will need
                                                            to copy those into
                                                            another excel file
                                                            and try bulk
                                                            updating again. If
                                                            you selected the
                                                            option "Triggers
                                                            Enabled" the failure
                                                            was due to loan
                                                            being locked by
                                                            another user. Please
                                                            try those again.
                                                        </p>
                                                        <Button
                                                            href={
                                                                uploadStatus
                                                                    ?.files?.[2]
                                                                    ?.url
                                                            }
                                                            target="_blank"
                                                            download
                                                        >
                                                            Download
                                                        </Button>
                                                    </Container>
                                                }
                                            />
                                        ) : null}
                                    </Container>
                                ) : statusId ===
                                  StatusEnum.FAILED ? (
                                    <Alert
                                        title="Failed"
                                        severity="error"
                                        content={
                                            uploadStatus?.files
                                                ? `${
                                                      uploadStatus?.files?.find?.(
                                                          (file) =>
                                                              file.fileType ===
                                                              "FileToProcess"
                                                      )?.fileName
                                                  } was not processed successfully.`
                                                : ""
                                        }
                                        errors={uploadStatus?.errors}
                                    />
                                ) : null}
                                <br />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={triggersEnabled}
                                            onChange={() =>
                                                setTriggersEnabled(
                                                    !triggersEnabled
                                                )
                                            }
                                        />
                                    }
                                    label="Open/Close (Triggers Enabled)"
                                />
                                <br />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={auditEnabled}
                                            onChange={() =>
                                                setauditEnabled(!auditEnabled)
                                            }
                                        />
                                    }
                                    label="Backup File Enabled"
                                />
                                <br />
                                <Uploader
                                    buttonText="Upload Bulk Update File"
                                    fileHandler={(file) => processUpload(file)}
                                    options={{
                                        acceptedFiles: [".xlsx"],
                                        filesLimit: 1,
                                    }}
                                />
                            </Container>
                        )}
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card className={classes.infoCard}>
                        <CardHeader
                            title={
                                <Typography variant="h4">
                                    Sample Bulk Update File
                                </Typography>
                            }
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary">
                                <a
                                    href="assets/sample-bulk-loan-update-template.xlsx"
                                    target="_blank"
                                >
                                    Click Me To Download!
                                </a>
                                <br />
                                Be sure to include the Loan GUID in column A for
                                each bulk update. Then each column thereafter
                                should have the encompass FieldID you would like
                                to update.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card className={classes.infoCard}>
                        <CardHeader
                            title={
                                <Typography variant="h4">
                                    Restrict Access To Fields
                                </Typography>
                            }
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary">
                                Want various departments to use this tool?{" "}
                                <a
                                    href="#!/bulkupdater/fieldaccess"
                                    target="_blank"
                                >
                                    Restrict Access
                                </a>{" "}
                                can give each Encompass persona access to only
                                the fields you want them to update! If they try
                                to update a field that you haven't given them
                                access to they will get an error asking them to
                                contact the Encompass administrator!
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card className={classes.infoCard}>
                        <CardHeader
                            title={
                                <Typography variant="h4">
                                    Invite Users
                                </Typography>
                            }
                        />
                        <CardContent>
                            <Typography variant="body2" color="textSecondary">
                                Lets get everyone on at your company using this
                                new tool! Just{" "}
                                <a
                                    href="#!/configuration/manageusers"
                                    target="_blank"
                                >
                                    Invite
                                </a>{" "}
                                them! Its quick and easy. We recommend
                                configuring the field access permissions first.
                                Once you have the fields defined, based on their
                                persona, you're off to the races!
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}
