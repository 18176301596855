import { useCallback, useEffect, useState } from 'react';
import useInterval from '../../hooks/useInterval';
import { api } from '../../support/httpClient';

export const useBulkUpdateHistory = () => {
  const [history, setHistory] = useState();
  const [shouldRefresh, refresh] = useState();
  useEffect(() => {
    (async () => {
      let response = await api.get('/bulkupdater/history');
      if (response?.data?.bulkUpdates) {
        setHistory(response.data.bulkUpdates);
      }
    })();
  }, [shouldRefresh]);

  return { data: history, refresh };
};

export const useFieldAccessRules = () => {
  const [rules, setRules] = useState();
  const [shouldRefresh, refresh] = useState();

  useEffect(() => {
    (async () => {
      let response = await api.get('/bulkupdater/fieldaccess');
      if (response?.data?.fieldAccessRules) {
        setRules(response.data.fieldAccessRules);
      }
    })();
  }, [shouldRefresh]);

  return { data: rules, refresh };
};

// need to verify how the rules are added
export const useAddFieldAccessRule = () => {
  const [shouldAdd, addRule] = useState();
  const [ruleStatus, setStatus] = useState();

  useEffect(() => {
    if (shouldAdd) {
      (async () => {
        let response = await api.post('/bulkupdater/fieldaccess/create', shouldAdd);
        if (response?.data) setStatus(response.data);
        addRule(null);
      })();
    }
  }, [shouldAdd]);

  return { ruleStatus, addRule };
};

export const useBulkUpdateUploader = () => {
  const [newUpload, upload] = useState();
  const [created, setCreated] = useState();
  const [fileUploaded, setFileUploaded] = useState();
  const [uploadStatus, setUploadStatus] = useState({ status: { id: 0 }, files: [] });

  const _setUploadStatus = useCallback(setUploadStatus, [uploadStatus]);

  useEffect(() => {
    if (newUpload)
      (async () => {
        let response = await api.post(
          '/bulkupdater/create',
          //{"triggersEnabled":false,"auditEnabled":true}
          newUpload.triggers,
        );
        if (response?.data) setUploadStatus(response.data);
        setCreated(true);
        setFileUploaded(false);
      })();
  }, [newUpload]);

  useEffect(() => {
    if (newUpload && created)
      (async () => {
        let response = await api.post('/bulkupdater/fileupload', newUpload.formData, {
          headers: { 'mi-data': uploadStatus.id },
        });
        // if (response?.data) setUploadStatus(response.data);
        setFileUploaded(true);
        upload(null);
        setCreated(false);
      })();
  }, [created]);

  // useEffect(async () => {
  //     if (fileUploaded) {
  //         getStatus(uploadStatus.id, setUploadStatus);
  //     }
  // }, [fileUploaded]);

  useInterval(
    () => {
      const id = uploadStatus.id;
      (async () => {
        // const _setUploadStatus = setUploadStatus;
        console.log('🚀 ~ file: updateAPI.js ~ line 101 ~ setUploadStatus', _setUploadStatus);
        let i = 0;
        if (i < 60) {
          let response = await api.get(`/bulkupdater/status?id=${id}`);
          // if (typeof _setUploadStatus !== 'function') {
          //     console.log('🚀 ~ file: updateAPI.js ~ line 105 ~ setTimeout ~ setUploadStatus', _setUploadStatus, new Error('funky upload status says what?'))
          // }
          if (response.data.status.id > 2) {
            _setUploadStatus?.(response.data);
            setFileUploaded(false);
          }
        } else
          _setUploadStatus?.({
            status: { id: 3 },
            errors: [
              {
                id: 0,
                message: 'The request timed out.',
                actionRequired: 'Check your connection, and try again.',
              },
            ],
          });
      })();
      // setUploadStatus(newStatus)
    },
    fileUploaded ? 500 : null,
    {
      maxAttempts: 60,
      onMaxAttempts: () => {
        _setUploadStatus?.({
          status: { id: 3 },
          errors: [
            {
              id: 0,
              message: 'The request timed out.',
              actionRequired: 'Check your connection, and try again.',
            },
          ],
        });
      },
    },
  );

  return { uploadStatus, upload };
};
