import React from "react";
import { useBulkUpdateHistory } from "./updateAPI";
import Table from "../../components/Table";
import { getDurationString } from "../../support/stringFormats";

const DetailView = (props) => {
    let record = props.data;
    return (
        <ul>
            <li>
                <strong>{"Audit Enabled:  "}</strong>
                {record.auditEnabled ? "True" : "False"}
            </li>
            <li>
                <strong>{"Field Count:  "}</strong>
                {record.fieldCount}
            </li>
            <li>
                <strong>{"Started:  "}</strong>
                {`${new Date(record.started).toLocaleDateString()} ${new Date(
                    record.started
                ).toLocaleTimeString()}`}
            </li>
            <li>
                <strong>{"Finished:  "}</strong>
                {`${new Date(record.finished).toLocaleDateString()} ${new Date(
                    record.finished
                ).toLocaleTimeString()}`}
            </li>
            <li>
                <strong>{"Time It Took:  "}</strong>
                {getDurationString(record.timeItTook)}
            </li>
            <li>
                <strong>{"Encompass Version:  "}</strong>
                {record.clientVersion}
            </li>
            {record.auditFileUrl && record.auditFileUrl.length > 0 ? (
                <li>
                    <strong>{"Audit File:  "}</strong>
                    <a href={record.auditFileUrl} download>
                        Download Here
                    </a>
                </li>
            ) : (
                ""
            )}
            {record.errorMessage && record.errorMessage.length > 0 ? (
                <li>
                    <strong>{"Error Message:  "}</strong>
                    {record.errorMessage}
                </li>
            ) : (
                ""
            )}
        </ul>
    );
};

const columns = [
    { name: "Task ID", selector: "id", sortable: true },
    { name: "User", selector: "user", sortable: true, wrap: true, grow: 3 },
    { name: "Status", selector: "status", sortable: true },
    {
        name: "File Name",
        selector: "fileName",
        sortable: true,
        wrap: true,
        grow: 3,
    },
    { name: "Loan Count", selector: "loanCount", sortable: true },
    {
        name: "Processed Loan(s)",
        selector: "processedLoanCount",
        sortable: true,
    },
    {
        name: "Triggers Enabled",
        selector: "triggersEnabled",
        sortable: true,
        format: (row) => (row.triggersEnabled ? "True" : "False"),
    },
];

const UpdateHistory = () => {
    const {data, refresh} = useBulkUpdateHistory();
    
    return (
        <Table
            title="Bulk Update History"
            columns={columns}
            searchable
            detailView={DetailView}
            data={data}
            refresh={refresh}
        />
    );
};
export default UpdateHistory;
