import React, { useEffect, useState } from "react";
import { Card, Grid, makeStyles } from "@material-ui/core";
import Table from "../../components/Table";
import {
    Today,
    ViewWeek,
    DateRange,
    History as HistoryIcon,
} from "@material-ui/icons";
import { useDocumentHistory, useDocumentHistoryTasks } from "./docimporterAPI";
import {
    getDurationString,
    getFileSizeString,
} from "../../support/stringFormats";
import { DashboardWidget } from "../../components/DashboardWidget";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100vh",
    },
    paper: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.secondary,
    },
    fullHeight: {
        height: "100%",
    },
    mainCard: {
        height: "60%",
    },
    infoCard: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.background.tertiary,
    },
    statItems: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
    },
    statList: {
        width: "100%",
        justify: "space-around",
        color: theme.palette.text.secondary,
        hr: {
            alginSelf: "stretch",
            height: "auto",
        },
        flexWrap: "nowrap",
    },
}));

const columns = [
    { name: "Loan Number", selector: "loanNumber", sortable: true },
    { name: "Status", selector: "status.name", sortable: true },
    { name: "Retry Count", selector: "retryCount", sortable: true },
    {
        name: "File Name",
        selector: "fileName",
        sortable: true,
        wrap: true,
        grow: 2,
    },
    {
        name: "File Size",
        selector: "fileSize",
        sortable: true,
        format: (row) => getFileSizeString(parseInt(row.fileSize)),
    },
    {
        name: "Time It Took",
        selector: "timeItTook",
        sortable: true,
        format: (row) => getDurationString(parseFloat(row.timeItTook)),
    },
];

const ImporterHistory = () => {
    const classes = useStyles();
    const imports = useDocumentHistory();
    const {data, refresh} = useDocumentHistoryTasks();

    return (
        <Grid container spacing={3} className={classes.gridContainer}>
            <DashboardWidget
                items={[
                    {
                        title: "Document Imports Today",
                        detail: imports?.importsToday || 0,
                        icon: <Today />,
                    },
                    {
                        title: "Document Imports This Week",
                        detail: imports?.importsWeek || 0,
                        icon: <ViewWeek />,
                    },
                    {
                        title: "Document Imports This Month",
                        detail: imports?.importsThisMonth || 0,
                        icon: <DateRange />,
                    },
                    {
                        title: "Document Imports This Year",
                        detail: imports?.importsThisYear || 0,
                        icon: <HistoryIcon />,
                    },
                ]}
            />
            <Grid item sm={12}>
                <Card className={classes.paper}>
                    <Table
                        title="Document Imports"
                        columns={columns}
                        searchable
                        data={data}
                        refresh={refresh}
                        exportable
                    />
                </Card>
            </Grid>
        </Grid>
    );
};
export default ImporterHistory;
