import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Collapse, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  top: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

export default function MyAlert(props) {
  const { title, content, errors = [], severity } = props;
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              className={classes.top}
              onClick={() => setOpen(false)}
            >
              <CloseIcon fonSize="inherit" />
            </IconButton>
          }
          variant="outlined"
          severity={severity}
        >
          <AlertTitle>{title}</AlertTitle>
          {content}
          {errors.length > 0 ? (
            <ul>
              {errors.map((error, i) => (
                <li key={`error${i}`}>
                  <strong>Error {error.id}</strong>: {error.message} <br />
                  <strong>Suggested Action:</strong>&nbsp;
                  {error.actionRequired}
                </li>
              ))}
            </ul>
          ) : null}
        </Alert>
      </Collapse>
    </div>
  );
}
